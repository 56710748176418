import React, {useState, useEffect} from 'react';
import { ReactNotifications } from 'react-notifications-component';
import Wrapper from '../layout/Wrapper/Wrapper';
import RoutesAside from '../layout/Aside/RoutesAside';
import Portal from '../layout/Portal/Portal';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import {pages} from '../menu';

const App = () => {
    const [showWhats, setShowWhats] = useState(true);

    const date = new Date();
    const hour = date.getHours();
    const isOpen = ((hour > 9 && hour < 12) || (hour > 13 && hour < 18));
    const getMessage = () => {
        if (isOpen) {
            return 'Olá! 🤝 \nDiga-me no que posso te ajudar?'
        }

        return 'Olá, desculpe-nos, estamos fechados no momento. Nossos horários de funcionamento são das 08:00 até as 12:00 e das 13:30 até as 18:00.\n ' +
            'Obrigado pela compreensão.'
    }

    const getMessageStatus = () => {
        if (isOpen) {
            return 'Normalmente responde dentro de 30 minutos. \nEstamos Online'
        }

        return 'Deixe-nos uma mensagem. \nEstamos Offline'
    }

    useEffect(() => {
        if (window.location.href.indexOf(pages.externalSchedule.pathId) > 0) {
            setShowWhats(false);
        } else {
            setShowWhats(true);
        }
    }, []);


    return (
	    <>
            {
                showWhats && (
                    <FloatingWhatsApp
                        phoneNumber="5554999188533"
                        accountName="Suporte EiBarber"
                        allowEsc
                        placeholder='Digite sua dúvida..'
                        avatar={'https://api.eibarber.com.br/images/logoBigode.png'}
                        allowClickAway
                        darkMode
                        notification
                        style={{'height': '0%'}}
                        notificationSound
                        chatboxHeight={400}
                        chatMessage={getMessage()}
                        statusMessage={getMessageStatus()}
                    />
                )
            }

            <div className='app'>
                <RoutesAside />
                <Wrapper />
            </div>
            <Portal id='portal-notification'>
                <ReactNotifications />
            </Portal>
        </>
	);
};

export default App;
