import React, {useContext, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Brand from '../Brand/Brand';
import Navigation from '../Navigation/Navigation';
import User from '../User/User';
import { dashboardMenu, pages } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Icon from "../../components/icon/Icon";
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const [doc, setDoc] = useState(false);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'false';//Manter como false

	const constraintsRef = useRef(null);

	const redirectDoc = () => {
		return window.open(`${window.location.origin}/${pages.documentation.path}`, "_blank");
	}

	useEffect(() => {
		setDoc(window.location.pathname.indexOf('/documentation') == 0);
	}, []);

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<Navigation menu={dashboardMenu} id='aside-dashboard' />
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => redirectDoc() }
								data-tour='documentation'>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										size='3x'
										icon={doc ? 'ToggleOn' : 'ToggleOff'}
										color={doc ? 'primary' : 'primary'}
										className='navigation-icon'
									/>
									<span className='navigation-text' style={{'fontSize': '20px'}}>
										Ajuda!
									</span>
								</span>
								<span className='navigation-link-extra'>
									<Icon
										size='2x'
										icon='Info'
										className={classNames(
											'navigation-notification',
											'text-primary',
											'animate__animated animate__heartBeat animate__infinite animate__slower',
										)}
									/>
								</span>
							</span>
							</div>
						</div>
					</nav>

					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
