import React, { lazy } from 'react';
import { pages } from '../menu';
import presentationAdmin from './files/admin'
import presentationFunctional from './files/functional'
import presentationFinancial from './files/financial'

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/Functional/Schendule/DashboardBooking') ),
	DOCUMENTATION: lazy(() => import('../pages/Functional/Documentation/Index') )
};

const presentation = [
	{
		path: pages.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: pages.documentation.path,
		element: <LANDING.DOCUMENTATION />,
		exact: true,
	},
    ...presentationAdmin,
	...presentationFunctional,
	...presentationFinancial
];

const contents = [...presentation];


export default contents;
