import React, {useState, useEffect} from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../../../components/bootstrap/Modal';
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import PropTypes from 'prop-types';
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Spinner from "../../../components/bootstrap/Spinner";
import showNotification from "../../../components/extras/showNotification";
import api from "../../../services/api";
import { setToken } from "../../../services/auth";

const ModalSchedules = ({ concluded, company, isOpen, setIsOpen }) => {
    const [loading, setLoading] = useState(false);
    const [schedules, setSchedules] = useState([]);

    const getSchedules = () => {
        setLoading(true)
        api.get(`externalSchedule/getSchendulesCustomer`).then(resp => {
            if (resp.data.length > 0) {
                setSchedules(resp.data);
                return;
            }

            setSchedules([]);
        });
    }

    const cancel = (id) => {
        setLoading(true)
        api.post(`externalSchedule/cancelSchedule/${id}`).then(resp => {
            setLoading(false)
            if (resp.data.error) {
                showNotification('Atenção!', resp.data.message, 'warning', true);
            } else {
                getSchedules();
                showNotification('Sucesso!', resp.data.message, 'success', true);
            }
        });
    }

    useEffect(() => {
        if (isOpen) getSchedules();
    }, [isOpen]);

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isStaticBackdrop
            isScrollable
            data-tour='search-modal'>
            <ModalHeader>
                <ModalTitle id='modalConfirm' className='text-success text-center'>
                    <Icon icon='FreeCancellation' size='2x' color='success' />
                    Agendamentos
                </ModalTitle>
            </ModalHeader>

            <ModalBody>
                <table className='table table-modern table-hover'>
                    <thead>
                    <tr>
                        <th scope='col'>Hora</th>
                        <th>Barbeiro</th>
                        <th scope='col' className='text-end'>
                            Ações
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {schedules.length <= 0 && (
                        <tr className='text-center'>
                            <td colSpan={3}>Nenhum agendamento encontrado.</td>
                        </tr>
                    )}
                    {schedules.map((schedule, key) => (
                        <tr key={key}>
                            <th>{ schedule.date_start_formatted }</th>
                            <th>{ schedule.barber_schendule.barber.full_name }</th>
                            <th className='text-end'>
                                <Button color='danger'
                                    icon='FreeCancellation'
                                    tag='a' title='Deletar'
                                    onClick={() => cancel(schedule.id)} disabled={loading}
                                />
                            </th>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </ModalBody>

            <ModalFooter>
                <Button
                    icon='ArrowBack'
                    color='primary'
                    className='border-0'
                    onClick={() => setIsOpen(false)}>
                    Voltar
                </Button>
            </ModalFooter>
        </Modal>
    )
}

ModalSchedules.propTypes = {
    user: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    company: PropTypes.object
}

ModalSchedules.defaultProps = {
    user: null,
    company: null
}

export default ModalSchedules;
