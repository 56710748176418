import React, {useState, useEffect} from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../../../components/bootstrap/Modal';
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import PropTypes from 'prop-types';
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Spinner from "../../../components/bootstrap/Spinner";
import axios from "../../../services/api";
import showNotification from "../../../components/extras/showNotification";
import {useFormik} from "formik";
import api from "../../../services/api";
import { setToken } from "../../../services/auth";

const STEPS = { NEW: 'NEW', LOGIN: 'LOGIN' }

const ModalCustomer = ({ concluded, company, isOpen, setIsOpen }) => {
    const formik = useFormik({
        initialValues: {
            company_id: null,
            full_name: '',
            username: '',
            new_customer: true,
            new_password: '',
            confirm_password: '',
            password: '',
        }
    });
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(STEPS.LOGIN);

    const resetPassword = () => {
        setLoading(true);
        return axios.post('/auth/sendResetPassword', { ...formik.values, email: formik.values.username }).then(res => {
            setLoading(false);

            if (res.data.error) {
                showNotification('Atenção!', res.data.message, 'warning', true);
            } else {
                showNotification('Sucesso!', res.data.message, 'success', true);
            }
        })
    }

    const login = () => {
        if (!formik.values.username || !formik.values.password) {
            return showNotification('Atenção!', 'Informe o Email/Telefone e Senha antes de continuar.', 'warning', true);
        }

        setLoading(true)
        api.post(`externalSchedule/login`, formik.values).then(resp => {
            setLoading(false)
            if (resp.data.error) {
                showNotification('Atenção!', resp.data.message, 'warning', true);
            } else {
                showNotification('Sucesso!', resp.data.message, 'success', true);
                setToken(resp.data.token);
                concluded({
                    user: resp.data.user,
                    token: resp.data.token
                })
            }
        });
    }

    const create = () => {
        setLoading(true)
        api.post(`externalSchedule/create`, formik.values).then(resp => {
            setLoading(false)
            if (resp.data.error) {
                showNotification('Atenção!', resp.data.message, 'warning', true);
            } else {
                showNotification('Sucesso!', resp.data.message, 'success', true);
                setToken(resp.data.token);
                concluded({
                    user: resp.data.user,
                    token: resp.data.token
                })
            }
        });
    }

    useEffect(() => {
        if (company != null) {
            formik.setFieldValue('company_id', company.id)
        }
    }, [company]);

    return (
        <Modal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isStaticBackdrop
            isScrollable
            data-tour='search-modal'>
            <ModalHeader>
                <ModalTitle id='modalConfirm' className='text-success text-center'>
                    <Icon icon='Login' size='2x' color='success' />
                    {step === STEPS.LOGIN ? 'Faça login!' : 'Criar conta!'}
                </ModalTitle>
            </ModalHeader>

            <ModalBody>
                {
                    step === STEPS.LOGIN ? (
                        <div className='row g-4'>
                            <div className='col-12'>
                                <FormGroup id='login-username' isFloating label='Telefone / E-mail'>
                                    <Input
                                        autoComplete='username'
                                        placeholder='Telefone / E-mail'
                                        name='username'
                                        onChange={formik.handleChange}
                                        value={formik.values.username}
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <FormGroup id='login-password' isFloating label='Senha'>
                                    <Input
                                        type='password'
                                        autoComplete='password'
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        name='password'
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-xs-6 text-rigth" style={{marginTop: 8, textAlign: 'end'}}>
                                <a onClick={resetPassword}>Esqueceu sua senha?</a>
                            </div>

                            <div className='col-12'>
                                <Button color='green' className='w-100 py-3' disabled={loading} onClick={ login }>
                                    {
                                        loading && (
                                            <Spinner isSmall inButton />
                                        )
                                    }
                                    {loading ? 'Aguarde...' : 'Entrar' }
                                </Button>

                                <Button
                                    color='primary'
                                    className='w-100 py-3'
                                    style={{ marginTop: 10 }}
                                    onClick={() => setStep(STEPS.NEW)}>
                                    Cadastrar-se
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className='row g-4'>
                            <div className='col-md-6'>
                                <FormGroup required id='full_name' label='Nome completo' isFloating>
                                    <Input
                                        placeholder='Nome completo'
                                        autoComplete='full_name'
                                        onChange={formik.handleChange}
                                        value={formik.values.full_name}
                                    />
                                </FormGroup>
                            </div>


                            {
                                (company !== null && company.configuration.required_phone === '1') ? (
                                    <>
                                        <div className='col-md-6'>
                                            <FormGroup required id='phone' label='Telefone' isFloating>
                                                <Input
                                                    mask="(99) 99999-9999"
                                                    placeholder='Telefone'
                                                    autoComplete='phone'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.phone}
                                                />
                                            </FormGroup>
                                        </div>

                                        <div className='col-md-6'>
                                            <FormGroup required id='email' label='E-mail' isFloating>
                                                <Input
                                                    placeholder='E-mail'
                                                    autoComplete='email'
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                />
                                            </FormGroup>
                                        </div>
                                    </>
                                ) : (
                                    <div className='col-md-6'>
                                        <FormGroup required id='username' label='Telefone / E-mail' isFloating>
                                            <Input
                                                placeholder='Telefone / E-mail'
                                                autoComplete='username'
                                                onChange={formik.handleChange}
                                                value={formik.values.username}
                                            />
                                        </FormGroup>
                                    </div>
                                )
                            }

                            <div className='col-md-6'>
                                <FormGroup required id='new_password' label='Senha' isFloating>
                                    <Input
                                        type='password'
                                        placeholder='Senha'
                                        onChange={formik.handleChange}
                                        value={formik.values.new_password}
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-md-6'>
                                <FormGroup required id='confirm_password' label='Confirme a Senha' isFloating>
                                    <Input
                                        type='password'
                                        placeholder='Confirme a Senha'
                                        autoComplete='family-name'
                                        onChange={formik.handleChange}
                                        value={formik.values.confirm_password}
                                    />
                                </FormGroup>
                            </div>

                            <div className='col-12'>
                                <Button color='green' className='w-100 py-3' disabled={loading}
                                        onClick={ create }>
                                    {
                                        loading && (
                                            <Spinner isSmall inButton />
                                        )
                                    }
                                    {loading ? 'Aguarde...' : 'Criar Conta' }
                                </Button>

                                <Button
                                    disabled={loading}
                                    color='primary'
                                    className='w-100 py-3'
                                    style={{ marginTop: 10 }}
                                    onClick={() => setStep(STEPS.LOGIN)}>
                                    {
                                        loading && (
                                            <Spinner isSmall inButton />
                                        )
                                    }
                                    {loading ? 'Aguarde...' : 'Já tenho conta' }
                                </Button>
                            </div>
                        </div>
                    )
                }
            </ModalBody>

            {
                (company !== null && company.configuration.required_record_external_schedule !== '1') && (
                    <ModalFooter>
                        <Button
                            icon='ArrowBack'
                            disabled={loading}
                            color='danger'
                            className='border-0'
                            onClick={() => setIsOpen(false)}>
                            Não quero logar
                        </Button>
                    </ModalFooter>
                )
            }
        </Modal>
    )
}

ModalCustomer.propTypes = {
    concluded: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    company: PropTypes.object
}

ModalCustomer.defaultProps = {
    postParams: null
}

export default ModalCustomer;
