import React, { lazy } from 'react';
import { pages } from '../../menu';

export const PREFIX_FINANCIAL = 'financial/*';
export const PREFIX_RECEIVE = 'receive/*';
export const PREFIX_PAY = 'pay/*';

const FINANCIAL = {
    BOX: {
        DASHBOARD: lazy(() => import('../../pages/Financial/Box/Dashboard'))
    },

    RECEIVE: {
        LIST: lazy(() => import('../../pages/Financial/AccountReceivePay/Receive/List')),
        EDIT: lazy(() => import('../../pages/Financial/AccountReceivePay/Receive/Edit'))
    },

    PAY: {
        LIST: lazy(() => import('../../pages/Financial/AccountReceivePay/Pay/List')),
        EDIT: lazy(() => import('../../pages/Financial/AccountReceivePay/Pay/Edit')),
    }
};

const presentation = [
    {
        path: pages.financial.dashboardBox.path,
        element: <FINANCIAL.BOX.DASHBOARD />,
        exact: true
    },

    {
        path: pages.financial.recevice.list.path,
        element: <FINANCIAL.RECEIVE.LIST />,
        exact: true
    },

    {
        path: pages.financial.recevice.editID.path,
        element: <FINANCIAL.RECEIVE.EDIT />,
        exact: true,
    },
    {
        path: `${pages.financial.recevice.editID.path}/:id`,
        element: <FINANCIAL.RECEIVE.EDIT />,
        exact: true
    },


    {
        path: pages.financial.pay.list.path,
        element: <FINANCIAL.PAY.LIST />,
        exact: true
    },

    {
        path: pages.financial.pay.editID.path,
        element: <FINANCIAL.PAY.EDIT />,
        exact: true,
    },
    {
        path: `${pages.financial.pay.editID.path}/:id`,
        element: <FINANCIAL.PAY.EDIT />,
        exact: true
    },


]

export default presentation;