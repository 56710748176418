export const STATUS = {
    'LOCKED': 'badge bg-danger',
    'ACTIVE': 'badge bg-success',
    'INACTIVE': 'badge bg-info'
};

export const STATUS_DESC = {
    'ACTIVE': 'Ativa',
    'INACTIVE': 'Inativa',
    'LOCKED': 'Bloqueada'
};

export const ROLES = [
    {value: 'ROLE_MANAGER', text: 'Gerente'},
    {value: 'ROLE_EMPLOYEE', text: 'Vendedor / Caixa'},
    {value: 'ROLE_BARBER', text: 'Barbeiro'},
    {value: 'ROLE_CUSTOMER', text: 'Cliente'},
    {value: 'ROLE_MASTER', text: 'Master *'},
];