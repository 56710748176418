import api from './api'
import {pages} from "../menu";
import {Navigate} from "react-router-dom";
import React from "react";

export const TOKEN_KEY = "@tokenUserEiBaber";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setToken = token => localStorage.setItem(TOKEN_KEY, token);

export const removeToken = () => localStorage.removeItem(TOKEN_KEY);

export const removeTokenAndRedirect = () => {
    if (getToken()) {
        removeToken();
        return window.location.reload();
    }
};

export const refreshToken = () => {
    return api.post('auth/refresh_token').then(response => {
        if (!response.data.token) {
            return removeTokenAndRedirect();
        }
        setToken(response.data.token);
        return response;
    }).catch(err => removeTokenAndRedirect());
};

export const me = () => {
    return api.post('auth/me').then(response => {
        if (!response.data) {
            return removeTokenAndRedirect();
        }
        return response;
    }).catch(err => removeTokenAndRedirect() );
};

export const getNotifications = () => {
    return api.post('auth/getNotification').then(response => {
        return response;
    });
};