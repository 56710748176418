export const pages = {
    externalSchedule: {
        id: 'externalSchedule',
        text: 'Agenda EiBarber',
        path: 'external/schedule/company',
        pathId: 'external/schedule',
        icon: 'Login',
    },

    documentation: {
        id: 'documentation',
        text: 'Documentação',
        path: 'documentation',
        icon: 'Documentation',
    },

    dashboard: {
    	id: 'dashboard',
    	text: 'Inicial',
    	path: '/',
    	icon: 'Dashboard',
    },

    user: {
        editID: {
            id: 'Editar perfil',
            text: 'editUserID',
            path: '/admin/user/edit',
            hide: true,
        },
    },

    login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},

    resetPassword: {
        id: 'resetPassword',
        text: 'Recuperar senha',
        path: 'auth-pages/reset-password/token',
        pathId: 'auth-pages/reset-password',
        icon: 'Login',
    },

    product: {
        editID: {
            id: 'editProductID',
            text: 'editProductID',
            path: '/product/edit',
            hide: true,
        },
        edit: {
            id: 'editProduct',
            text: 'Editar produto',
            path: '/product/edit/1',
            icon: 'ViewInAr'
        },
        list: {
            id: 'listProduct',
            text: 'Produtos',
            path: '/product/list',
            icon: 'ViewInAr'
        },
        listService: {
            id: 'listService',
            text: 'Serviços',
            path: '/service/list',
            icon: 'Scissors'
        },
        editServiceID: {
            id: 'editServiceID',
            text: 'editServiceID',
            path: '/service/edit',
            hide: true,
        },
        editService: {
            id: 'editService',
            text: 'Editar serviço',
            path: '/service/edit/1',
            icon: 'Scissors'
        },
    },

    departament: {
        list: {
            id: 'listDepartament',
            text: 'Departamentos',
            path: '/departament/list',
            icon: 'Inventory2'
        },
    },

    provider: {
        list: {
            id: 'listProvider',
            text: 'Fornecedor',
            path: '/provider/list',
            icon: 'LocalShipping'
        }
    },

    employee: {
        barber: {
            edit: {
                id: 'editBarber',
                text: 'Editar Barbeiro',
                path: '/employee/barber/edit',
            },
        },
        salesman: {
            edit: {
                id: 'editSalesman',
                text: 'Editar Vendedor',
                path: '/employee/salesman/edit',
            },
        },
        dashboard: {
            id: 'employee',
            text: 'Funcionários',
            path: '/employee',
        },
        list: {
            id: 'listEmployee',
            text: 'Employee',
            path: '/employee/list',
        },
    },

    customer: {
        list: {
            id: 'list',
            text: 'Clientes',
            path: '/customer/list',
            icon: 'PersonSearch'
        }
    },

    financial: {
        dashboardBox: {
            id: 'dashboardBox',
            text: 'Caixa',
            path: 'financial/dashboard',
            icon: 'Money'
        },
        recevice: {
            list: {
                id: 'listReceice',
                text: 'Conta Receber',
                path: '/receive',
                icon: 'AttachMoney'
            },
            editID: {
                id: 'editAccountReceiveID',
                text: 'editAccountReceiveID',
                path: '/receive/edit',
                hide: true,
            },
            edit: {
                id: 'editAccountReceive',
                text: 'Conta Receber',
                path: '/receive/edit/1',
            },
        },
        pay: {
            list: {
                id: 'listPay',
                text: 'Conta Pagar',
                path: '/pay',
                icon: 'MoneyOff'
            },

            editID: {
                id: 'editAccountPayID',
                text: 'editAccountPayID',
                path: '/pay/edit',
                hide: true,
            },
            edit: {
                id: 'editAccountPay',
                text: 'Bloqueio de agenta',
                path: '/pay/edit/1',
            },
        }
    },

    schendule: {
        dashboardBooking: {
            id: 'list',
            text: 'Agenda',
            path: '/schedule',
            icon: 'CalendarCheck'
        }
    },

    command: {
        dashboardCommand: {
            id: 'list',
            text: 'Comandas',
            path: '/command',
            icon: 'InsertDriveFile'
        }
    },

    admin: {
        plan: {
            id: 'plan',
            text: 'Planos',
            path: '/admin/plan',
            icon: 'AddShoppingCart'
        },

        raffle: {
            id: 'raffle',
            text: 'Sorteador',
            path: '/admin/raffle',
            icon: 'SupervisorAccount'
        },

        firstAccess: {
            id: 'firstAccess',
            text: 'Configurações Iniciais',
            path: '/admin/first-access',
            icon: 'SupervisorAccount'
        },

        configuration: {
            id: 'configuration',
            text: 'Configurações',
            path: '/admin/configuration',
            icon: 'SupervisorAccount'
        },

        reports: {
            id: 'reports',
            text: 'Relatórios',
            path: '/reports',
            icon: 'InsertDriveFile'
        },

        blockSchendule: {
            list: {
                id: 'blockSchendule',
                text: 'Bloqueio de agenda',
                path: '/admin/blockSchendule/list',
                icon: 'CalendarX'
            },
            editID: {
                id: 'editBlockSchenduleID',
                text: 'editBlockSchenduleID',
                path: '/admin/blockSchendule/edit',
                hide: true,
            },
            edit: {
                id: 'editBlockSchendule',
                text: 'Bloqueio de agenta',
                path: '/admin/blockSchendule/edit/1',
            },
        }
    },

    master: {
        company: {
            editID: {
                id: 'editCompanyID',
                text: 'editCompanyID',
                path: '/master/company/edit',
                hide: true,
            },
            edit: {
                id: 'editCompany',
                text: 'Editar empresa',
                path: '/master/company/edit/1',
                icon: 'Business'
            },
            list: {
                id: 'listCompany',
                text: 'Empresas',
                path: '/master/company/list',
                icon: 'Business'
            },
        },
        userCompany: {
            list: {
                id: 'listUserCompany',
                text: 'Víncular usuários',
                path: '/master/userCompany/list',
                icon: 'Box'
            },
        }
    }
};

export const dashboardMenu = {
	// dashboard: {
	// 	id: 'dashboard',
	// 	text: 'Inicial',
	// 	path: '/',
	// 	icon: 'Dashboard',
	// 	subMenu: null,
	// },
    schendule: {
        id: 'schendule',
		text: 'Agenda',
		path: pages.schendule.dashboardBooking.path,
		icon: pages.schendule.dashboardBooking.icon,
		subMenu: null,
	},
    command: {
        id: 'dashboardCommand',
        text: pages.command.dashboardCommand.text,
        path: pages.command.dashboardCommand.path,
        icon: pages.command.dashboardCommand.icon,
        subMenu: null,
    },
    financial: {
        id: 'financial',
        path: '/financial',
        text: 'Financeiro',
        icon: 'TableView',
        subMenu: {
            dashboardBox: {
                id: 'dashboardBox',
                text: pages.financial.dashboardBox.text,
                path: pages.financial.dashboardBox.path,
                icon: pages.financial.dashboardBox.icon
            },
            receive: {
                id: pages.financial.recevice.list.id,
                text: pages.financial.recevice.list.text,
                path: pages.financial.recevice.list.path,
                icon: pages.financial.recevice.list.icon
            },

            pay: {
                id: pages.financial.pay.list.id,
                text: pages.financial.pay.list.text,
                path: pages.financial.pay.list.path,
                icon: pages.financial.pay.list.icon
            },
        },
    },
    customer: {
        id: 'customer',
		text: 'Clientes',
		path: pages.customer.list.path,
		icon: pages.customer.list.icon,
		subMenu: null,
	},
    stock: {
		id: 'stock',
		text: 'Estoque',
		path: 'stock',
		icon: 'Box',
		subMenu: {
            departament: {
                id: 'departament',
                text: pages.departament.list.text,
                path: pages.departament.list.path,
                icon: pages.departament.list.icon
            },

            product: {
                id: 'product',
                text: pages.product.list.text,
                path: pages.product.list.path,
                icon: pages.product.list.icon
            },

            provider: {
                id: 'provider',
                text: pages.provider.list.text,
                path: pages.provider.list.path,
                icon: pages.provider.list.icon
            },
        },
	},
    gerencia: {
        id: 'gerenciarSistema',
        text: 'Gerenciar Sistema',
        icon: 'ManageAccounts',
        role: 'ROLE_MANAGER',
        path: 'managerSys',
        subMenu: {
            raffle: {
                id: pages.admin.raffle.id,
                text: pages.admin.raffle.text,
                path: pages.admin.raffle.path,
                icon: pages.admin.raffle.icon
            },
            configuration: {
                id: 'configuration',
                text: pages.admin.configuration.text,
                path: pages.admin.configuration.path,
                icon: 'Settings'
            },
            reports: {
                id: pages.admin.reports.id,
                text: pages.admin.reports.text,
                path: pages.admin.reports.path,
                icon: pages.admin.reports.icon
            },
            service: {
                id: 'service',
                text: pages.product.listService.text,
                path: pages.product.listService.path,
                icon: pages.product.listService.icon,
            },
            employee: {
                id: 'employee',
                path: pages.employee.dashboard.path,
                text: pages.employee.dashboard.text,
                icon: 'People'
            },
            blockSchendule: {
                id: pages.admin.blockSchendule.list.id,
                path: pages.admin.blockSchendule.list.path,
                text: pages.admin.blockSchendule.list.text,
                icon: pages.admin.blockSchendule.list.icon
            },
        }
    },

    master: {
        id: 'gerenciaEiBarber',
        text: 'Gerenciar EiBarber',
        icon: 'SupervisedUserCircle',
        role: 'ROLE_MASTER',
        path: '/managerEiBarber',
        subMenu: {
            company: {
                id: pages.master.company.list.id,
                text: pages.master.company.list.text,
                path: pages.master.company.list.path,
                icon: pages.master.company.list.icon,
            },
            userCompany: {
                id: pages.master.userCompany.list.id,
                text: pages.master.userCompany.list.text,
                path: pages.master.userCompany.list.path,
                icon: pages.master.userCompany.list.icon,
            }
        }
    },
};