import React from 'react';
import DashboardHeader from '../pages/Headers/DashboardHeader';

const headers = [
	{ path: 'external/*', element: null, exact: true },
	{ path: 'auth-pages/*', element: null, exact: true },
	{ path: '*', element: <DashboardHeader />, exact: true },
	{
		path: `*`
	},
];

export default headers;
