import { createSlice } from '@reduxjs/toolkit'
import { formatMoney, formatForCalc } from "../../helper/functions";

const getValuesCommand = (items) => {
    let total = 0, totalDiscount = 0;
    items.forEach(item => {
        if (item.payd_with_points === '0') {
            total += formatForCalc(item.value_total);
            totalDiscount += formatForCalc(item.value_discount);
        }
    })

    total = formatMoney(total);
    let payments = [{
        account_bank_id: '',
        form_payment_id: '',
        form_payment: {
            type: ''
        },
        value_total: total
    }];

    let invoicing = {
        value_total: total,
        value_final: total,
        value_remaining: total,
        value_remaining_form: total,
        amount_paid: total,
        value_discount: '0,00',
        value_increase: '0,00'
    };

    return [payments, invoicing]
}

export const command = createSlice({
    name: 'command',
    initialState: {
        commandId: null,
        customer_points: 0,
        command: {},
        payments: [],
        items: [],
        invoicing: {
            amount_paid: '0,00',
            value_discount: '0,00',
            value_total: '0,00',
            value_final: '0,00',
            value_remaining: '0,00',
            value_remaining_form: '0,00',
            loyalty_points: 0,
            loyalty_discount_value: 0
        }
    },
    reducers: {
        setCommandId: (state, action) => {
            state.commandId = action.payload;
        },

        setCommand: (state, action) => {
            let command = action.payload;

            state.command = command;
            state.items = command.items;
            state.customer_points = parseInt(command.customer?.user_company?.loyalty_program_points);

            let result = getValuesCommand(command.items);
            state.payments = result[0];
            state.invoicing = result[1];
        },

        updatePayment: (state, action) => {
            let valuePaid = 0;
            if (action.payload.payment) {
                state.payments[action.payload.key] = action.payload.payment;
            }

            state.payments.map(pay => {
                valuePaid += formatForCalc(pay.value_total);
            })
            state.invoicing.value_remaining_form = formatMoney(formatForCalc(state.invoicing.value_final) - valuePaid);
            state.invoicing.amount_paid = formatMoney(valuePaid);
        },

        calculateDiscount: (state, action) => {
            let discount = formatForCalc(action.payload);

            let result = formatForCalc(state.invoicing.value_total) - discount;

            if (result < 0) {
                discount = 0;
            } else {
                result = formatMoney(result);
                state.invoicing.value_final = result;
            }

            let valueRemaining = formatMoney(formatForCalc(state.invoicing.value_total) - formatForCalc(state.invoicing.amount_paid) - discount);
            state.invoicing.value_remaining_form = valueRemaining;
            state.invoicing.value_discount = formatMoney(discount);
        },

        calculateIncrease: (state, action) => {
            let increase = formatForCalc(action.payload);

            let result = formatForCalc(state.invoicing.value_total) + increase;

            result = formatMoney(result);
            state.invoicing.value_final = result;

            let valueRemaining = formatMoney(increase + formatForCalc(state.invoicing.value_total) - formatForCalc(state.invoicing.amount_paid) - formatForCalc(state.invoicing.value_discount));
            state.invoicing.value_remaining_form = valueRemaining;
            state.invoicing.value_remaining = formatMoney((formatForCalc(state.invoicing.value_total) + increase));
            state.invoicing.value_increase = formatMoney(increase);
        },

        removePayment: (state, action) => {
            state.payments = state.payments.filter((pay, i) => i !== action.payload);
        },

        newPayment: (state) => {
            state.invoicing.value_remaining = state.invoicing.value_remaining_form;
            state.invoicing.value_remaining_form = '0,00';

            state.payments = [
                ...state.payments,
                {
                    account_bank_id: '',
                    form_payment_id: '',
                    form_payment: {type: ''},
                    value_total: state.invoicing.value_remaining,
                    installments: []
                }
            ];
        },

        setPointsCommand: (state, action) => {
            let valueDiscount = action.payload.value;
            let result = formatForCalc(state.invoicing.value_total) - valueDiscount;

            if (result < 0) {
                valueDiscount = 0;
            } else {
                result = formatMoney(result);
                state.invoicing.value_final = result;
            }

            let valueRemaining = formatMoney(formatForCalc(state.invoicing.value_total) - formatForCalc(state.invoicing.amount_paid) - valueDiscount);

            state.invoicing.value_remaining_form = valueRemaining;
            state.invoicing.loyalty_points = action.payload.points;
            state.invoicing.loyalty_discount_value = valueDiscount;
        }
    },
})

export const {
    setCommandId,
    setCommand,
    updatePayment,
    calculateDiscount,
    removePayment,
    newPayment,
    setPointsCommand,
    calculateIncrease
} = command.actions

export default command.reducer