import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { pages } from '../../menu';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import UserImage from '../../assets/img/link/manDefault.png';
import AuthContext from '../../contexts/authContext';
import SelectCompany from "../../pages/Auth/SelectCompany";
import Icon from '../../components/icon/Icon';
import api from '../../services/api';
import showNotification from '../../components/extras/showNotification';
import {removeToken} from "../../services/auth";

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	const navigate = useNavigate();
	const { user, removeAuth, setAuth } = useContext(AuthContext);
	const [companys, setCompanys] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const pathFile = (user !== null && user.file_system !== null) ? (process.env.REACT_APP_HOST_API + user.file_system.path) : UserImage;

	const logout = () => {
		removeToken();
		removeAuth();
		api.post('/auth/logout').then(() => {
			showNotification('Sucesso!', `Sessão offline.`, 'success', true);
			return navigate(`../${pages.login.path}`);
		}).catch(() => navigate(`../${pages.login.path}`));
	};

	const loginSuccess = (res) => {
		showNotification('Sucesso!', `Empresa alterada com sucesso, ${res.data.user.full_name}`, 'success');
		setAuth(res.data);
		return navigate('/');
	}

	const editUser = () => {
		return navigate(pages.user.editID.path);
	};

	const handleCompany = () => api.post(`/user/getCompanys/${user.id}`).then((resp) => {
		setCompanys(resp.data);
		setIsOpen(true);
	} );

	return (
		<Dropdown>
			<SelectCompany
				companys={companys}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				loginSuccess={loginSuccess}
			/>


			<DropdownToggle hasIcon={false}>
				<div className='user'>
					<UserAvatar srcSet={pathFile} src={pathFile} />
					<div className='user-info'>
						<div className='user-name'>
							{user?.first_name}
							<Icon icon='Verified' className='ms-1' color='primary' />
						</div>
						{/*<div className='user-sub-title'>{userAlter.last_permission}</div>*/}
					</div>
				</div>
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem>
					<div onClick={editUser}>
						<span className='navigation-link navigation-link-pill'>
							<span className='navigation-link-info'>
								<Icon
									icon='AccountBox'
									className='navigation-icon'
								/>
								<span className='navigation-text'>Perfil</span>
							</span>
						</span>
					</div>
				</DropdownItem>

				{
					user !== null && user.companys.length > 1 && (
						<DropdownItem>
							<div onClick={handleCompany}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon='Business'
											className='navigation-icon'
										/>
										<span className='navigation-text'>Selecionar empresa</span>
									</span>
								</span>
							</div>
						</DropdownItem>
					)
				}
				<DropdownItem isDivider />
				<DropdownItem>
					<div onClick={logout}>
						<span className='navigation-link navigation-link-pill'>
							<span className='navigation-link-info'>
								<Icon icon='Logout' className='navigation-icon' />
								<span className='navigation-text'>Sair</span>
							</span>
						</span>
					</div>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default User;
