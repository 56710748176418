import { Store } from 'react-notifications-component';
import Icon from '../../components/icon/Icon';

let _settings = {
	insert: 'top',
	container: 'top-right',
	animationIn: ['animate__animated', 'animate__fadeIn'],
	animationOut: ['animate__animated', 'animate__fadeOut'],
	dismiss: {
		duration: 5000,
		pauseOnHover: true,
		onScreen: true,
		showIcon: true,
		waitForAnimation: true,
	},
};

const showNotification = (title, message, type = 'default', icon = false) => {
	if (message.length > 60) {
		_settings.dismiss.duration = 15000;
	}

	if (icon) {
		let iconType = type === 'success' ? 'Check' : (
			type === 'warning' ? 'Error' : 'Info'
		);


		title = (<span className='d-flex align-items-center'>
					<Icon icon={ iconType } size='lg' className='me-1' />
					<span>{ title }</span>
				</span>);

		Store.addNotification({
			title,
			message,
			type,
			..._settings,
		});
	} else {
		Store.addNotification({
			title,
			message,
			type,
			..._settings,
		});
	}
};

export default showNotification;
