import React, {useContext} from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {pages} from "../../menu";
import AuthContext from "../../contexts/authContext";
import {isAuthenticated} from "../../services/auth";

const PrivateRoute = ({ component, role }) => {
    const { user } = useContext(AuthContext);
    const location = useLocation();

    if (!isAuthenticated()) {
        return <Navigate to={`../${pages.login.path}`} state={{ from: location }} replace/>;
    }

    if (role !== undefined) {
        if (
            user !== null &&
            (user.user_company.roles.includes('ROLE_MASTER') ||
            (user.user_company.roles.includes('ROLE_EMPLOYEE') && role === 'ROLE_EMPLOYEE') ||
            (user.user_company.roles.includes('ROLE_MANAGER') && role !== 'ROLE_MASTER'))
        ) {
            return component;
        } else {
            return <Navigate to={`/`} />;
        }
    }

    return component;
}

PrivateRoute.propTypes = {
    component: PropTypes.element.isRequired,
    roles: PropTypes.array
};

export default PrivateRoute;