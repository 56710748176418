import React, {createContext, useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import { me, getToken } from '../services/auth';

const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [company, setCompany] = useState(null);

	const setAuth = (data) => {
		setCompany(data.user.current_company);
		setUser(data.user);
	}

	const removeAuth = () => {
		setCompany(null);
		setUser(null);
	}

	useEffect(() => {
		if (getToken()) {
			me().
				then(resp => setAuth(resp.data)).
				catch(err => console.warn('Permissão negada!!'));
		}

	}, []);

	const values = useMemo(
		() => ({
			user,
			setUser,
			company,
			setCompany,
			setAuth,
			removeAuth
		}),
		[user, company],
	);

	return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
