import React, {lazy} from 'react';
import { Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import { pages } from '../../menu';
import Login from '../../pages/Auth/Login';
import PrivateRoute from './PrivateRoute';
import Page404 from '../../pages/Page404';
import ResetPassword from "../../pages/Auth/ResetPassword";
import ExternalSchedule from '../../pages/ExternalSchedule/Index';

const routeLogin = {
	path: pages.login.path,
	element: <Login />,
	exact: true,
};

const routeResetPassword = {
	path:`${pages.resetPassword.pathId}/:token`,
	element: <ResetPassword />,
	exact: true,
};

const externalSchedule = {
	path:`${pages.externalSchedule.pathId}/:company`,
	element: <ExternalSchedule/>,
	exact: true,
}

const ContentRoutes = () => {
	return (
		<Routes>
			<Route path={externalSchedule.path} component={externalSchedule.element} {...externalSchedule} />
			<Route path={routeLogin.path} component={routeLogin.element} {...routeLogin} />
			<Route path={routeResetPassword.path} component={routeResetPassword.element} {...routeResetPassword} />
			<Route path='*' element={<Page404/>}/>
			
			{contents.map((page) => {
				return (
					<Route {...page} key={page.path} element={<PrivateRoute component={ page.element } role={page.role && page.role} />} />
				);
			})}
		</Routes>
	);
};

export default ContentRoutes;
