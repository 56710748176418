import React, {Children, cloneElement, useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterLeft,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from './bootstrap/Card';
import Button from './bootstrap/Button';
import Popovers from './bootstrap/Popovers';
import wizardContext from '../contexts/wizardContext';
import { useNavigate } from 'react-router-dom';
import Spinner from './bootstrap/Spinner';
import Fab from '@mui/material/Fab';
import Icon from "./icon/Icon";
import { isMobile } from '../helper/functions'

export const WizardItem = ({ id, title, children, className, ...props }) => {
	return (
		<section
			id={id}
			className={classNames('wizard-item', className)}
			role='tabpanel'
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</section>
	);
};
WizardItem.propTypes = {
	id: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	className: PropTypes.string,
};
WizardItem.defaultProps = {
	className: null,
	title: null,
};

const Wizard = ({ notSave, notNavigate, onSubmit, children, isHeader, color, stretch, handleAuxFunc, textAux, isAuxButton, ...props }) => {
	const { setOnStep, validNext, onLoading } = useContext(wizardContext);
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	const childCount = children.length;
	const navigate = useNavigate();
	const isMob = isMobile();

	useEffect(() => {
		if (props.handleStep) props.handleStep(activeItemIndex);
	}, [activeItemIndex]);

	const getTitleName = (i) => {
		return `Step ${(i + 1)}`;
	};

	const prevBtn = (
		<Button color={color} onClick={() => setActiveItemIndex(activeItemIndex - 1)} disabled={ !validNext } icon='SkipPrevious'>
			Anterior
		</Button>
	);

	const prevBtnNavigate = !notNavigate && (
		<Button color='primary' onClick={() => navigate(-1)} icon='SkipPrevious'>
			Voltar
		</Button>
	);

	const nextBtn = (
		<>
			{
				isAuxButton && (
					<Button
						className={classNames({ 'd-none': childCount === activeItemIndex + 1 })}
						aria-hidden={childCount === activeItemIndex + 1}
						color='primary'
						icon='FreeCancellation'
						style={{
							marginBottom: `5px`,
						}}
						data-tour='tour-next-wizard'
						onClick={handleAuxFunc}>
						{ textAux }
					</Button>
				)
			}
			<Button
				className={classNames({ 'd-none': childCount === activeItemIndex + 1 }, 'me-0')}
				aria-hidden={childCount === activeItemIndex + 1}
				color='success'
				style={{
					marginBottom: `5px`,
				}}
				disabled={ !validNext }
				icon='SkipNext'
				data-tour='tour-next-wizard'
				onClick={() => setActiveItemIndex(activeItemIndex + 1)}>
				Próximo
			</Button>
			<Button
				className={classNames({ 'd-none': childCount !== activeItemIndex + 1 || notSave })}
				icon='Save'
				style={{
					marginBottom: `5px`,
				}}
				aria-hidden={childCount !== activeItemIndex + 1 || notSave}
				disabled={ !validNext }
				onClick={ onSubmit }
				color='success'>
				{
					onLoading && (
						<Spinner isSmall inButton />
					)
				}
				{ onLoading ? 'Aguarde...' : 'Salvar' }
			</Button>
		</>
	);

	useEffect(() => {
		setOnStep(activeItemIndex);
	}, [activeItemIndex])

	return (
		<>

			{
				isMob && childCount !== (activeItemIndex + 1) && (
					<Fab
						className='floating-button'
						color="success"
						aria-label="add"
						onClick={ () => setActiveItemIndex(activeItemIndex + 1) }
						disabled={ !validNext }
					>
						<Icon icon='SkipNext' color='white' />
					</Fab>
				)
			}

			<Card stretch={stretch} tag='form' {...props}>
				{!!isHeader && (
					<CardHeader>
						<CardLabel icon='Assignment' iconColor={color}>
							{Children.map(children, (child, index) => (
								<CardTitle
									key={child.props.id}
									className={index !== activeItemIndex ? 'd-none' : null}>
									{child.props.title || getTitleName(index)}
								</CardTitle>
							))}
						</CardLabel>
						{isHeader === 'withButton' && (
							<CardActions>
								{!!activeItemIndex ? prevBtn : prevBtnNavigate}
								{nextBtn}
							</CardActions>
						)}
					</CardHeader>
				)}
				<CardBody isScrollable={!!stretch}>
					<div className='wizard-progress position-relative'>
						<div className='progress'>
							<div
								className={classNames('progress-bar', {
									[`bg-${color}`]: color !== 'primary',
								})}
								role='progressbar'
								style={{ width: `${(100 / (childCount - 1)) * activeItemIndex}%` }}
								aria-valuenow={(100 / (childCount - 1)) * activeItemIndex}
								aria-valuemin='0'
								aria-valuemax='100'
								aria-label='progress'
							/>
						</div>
						{Children.map(children, (child, index) => (
							<Popovers
								key={child.props.id}
								desc={child.props.title || getTitleName(index)}
								trigger='hover'>
								<button
									type='button'
									className={classNames(
										'wizard-progress-btn',
										'position-absolute p-0 top-0',
										'translate-middle',
										'btn btn-sm',
										{
											[`btn-${color}`]: activeItemIndex >= index,
											[`btn-dark`]: activeItemIndex < index,
										},
										'rounded-pill',
									)}
									style={{
										left: `${(100 / (childCount - 1)) * index}%`,
									}}
									disabled={ activeItemIndex < index && !validNext }
									onClick={() => setActiveItemIndex(index)}>
									{index + 1}
								</button>
							</Popovers>
						))}
					</div>

					<div className='wizard'>
						{Children.map(children, (child, index) =>
							cloneElement(child, {
								className: index !== activeItemIndex ? 'd-none' : '',
								'aria-hidden': index !== activeItemIndex,
							}),
						)}
					</div>
				</CardBody>
				<CardFooter>
					<CardFooterLeft>{!!activeItemIndex ? prevBtn : prevBtnNavigate}</CardFooterLeft>
					<CardFooterRight>{nextBtn}</CardFooterRight>
				</CardFooter>
			</Card>
		</>
	);
};
Wizard.propTypes = {
	children: PropTypes.node.isRequired,
	onSubmit: PropTypes.func,
	handleStep: PropTypes.func,
	notNavigate: PropTypes.bool,
	notSave: PropTypes.bool,
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'dark',
		'brand',
		'brand-two',
		'storybook',
	]),
	isHeader: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['withButton'])]),
	stretch: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['full', 'semi'])]),
	handleAuxFunc: PropTypes.func,
	textAux: PropTypes.string,
	isAuxButton: PropTypes.bool
};
Wizard.defaultProps = {
	onSubmit: null,
	isHeader: false,
	notNavigate: false,
	color: 'primary',
	stretch: null,
	handleAuxFunc: null,
	textAux: '',
	isAuxButton: false
};

export default Wizard;
