import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Aside from './Aside';
import { pages } from '../../menu';
const RoutesAside = () => {
    return (
        <Routes>
            <Route key='external/*' path='external/*' element={null} />
            <Route key='auth-pages/*' path='auth-pages/*' element={null} />
            <Route key={pages.admin.firstAccess.path} path={pages.admin.firstAccess.path} element={null} />
            <Route path='*' element={<Aside />} />
        </Routes>
    );
}

export default RoutesAside;