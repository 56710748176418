import React, {useState} from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import LogoSvg from '../../assets/img/LOGOEIBARBER.svg';
import showNotification from "../../components/extras/showNotification";
import axios from "../../services/api";
import {useNavigate, useParams} from "react-router-dom";
import { pages } from '../../menu';
import Spinner from "../../components/bootstrap/Spinner";

const initialStateUser = {
	confirm_password: '',
	password: '',
};

const ResetPassword = () => {
	const [userPassword, setUserPassword] = useState(initialStateUser);
	const [loading, setLoading] = useState(false);
	const { token } = useParams();
	const navigate = useNavigate();

	const validPassword = () => {
		if (userPassword.password !== userPassword.confirm_password) {
			return showNotification('Atenção!', 'Senhas não conferem.', 'warning', true);
		}

		setLoading(true);
		return axios.post('/auth/updatePassword', { ...userPassword, token }).then(res => {
			setLoading(false);

			if (res.data.error) {
				showNotification('Atenção!', res.data.message, 'warning', true);
			} else {
				showNotification('Sucesso!', res.data.message, 'success', true);
				return navigate(`../${pages.login.path}`);
			}
		})
	}

	return (
		<PageWrapper title={pages.resetPassword.text} className='bg-dark'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page' style={{'margin-top': '1.5rem'}}>
							<CardBody>
								<div className='image-center my-5 text-center'>
									<img src={LogoSvg} alt='Logo' width={350} height={250} />
								</div>
								<div className='col-12' color='warning'>
									<div className='text-center h4 text-muted mb-5'>
										Digite a nova senha!
									</div>
								</div>

								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup id='new-password' isFloating label='Nova Senha'>
											<Input
												type='password'
												autoComplete='password'
												onChange={(e) =>
													setUserPassword({
														...userPassword,
														password: e.target.value,
													})
												}
												name='password'
												value={userPassword.password}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='confirm-password' isFloating label='Confirme a Senha'>
											<Input
												type='password'
												autoComplete='password'
												onChange={(e) =>
													setUserPassword({
														...userPassword,
														confirm_password: e.target.value,
													})
												}
												name='password'
												value={userPassword.confirm_password}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<Button
											color='primary'
											className='w-100 py-3'
											icon='ArrowBack'
											onClick={() => navigate(`../${pages.login.path}`)}>
											Acessar
										</Button>
									</div>
									<div className='col-6'>
										<Button
											color='green'
											className='w-100 py-3'
											onClick={validPassword}
											icon='Save'
											disabled={loading}>
											{
												loading && (
													<Spinner isSmall inButton />
												)
											}
											{loading ? 'Aguarde...' : 'Salvar' }
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
    );
};

export default ResetPassword;
