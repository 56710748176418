import React, { useCallback } from 'react';
import classNames from 'classnames';
import Card, {CardBody, CardLabel, CardTabItem, CardTitle} from '../../../components/bootstrap/Card';
import Badge from '../../../components/bootstrap/Badge';

const ItemExternalSchedule = ({ id, file_system, surname, isSelected, estimated_time, sale_value, tags, color, funcSelect }) => {

    const handleOnClick = useCallback(
        () => funcSelect(id),
        [funcSelect, id],
    );

    return (
        <Card style={{border: '4px solid #D0CBC9'}} className='cursor-pointer shadow-3d-primary shadow-3d-hover'
            onClick={handleOnClick}>
            <CardBody>
                <div className={classNames(
                        'ratio ratio-1x1',
                        'rounded-2',
                        `bg-lo25-${color}`,
                        'mb-2',
                    )}>
                    <img src={process.env.REACT_APP_HOST_API + (file_system !== null ? file_system.path : '/images/noImage.png')}
                        alt='' width='100%' height='auto' className='object-fit-contain p-3'
                    />
                </div>

                <CardLabel icon={isSelected ? 'CheckCircle' : 'Circle'} iconColor={isSelected ? 'success' : 'primary'}>
                    <CardTitle style={{color: '#D0CBC9 !important', fontWeight: 800}}>{surname}</CardTitle>
                </CardLabel>

                <p className='text-muted truncate-line-2' style={{color: '#D0CBC9 !important', fontSize: '16px', fontWeight: 700}}>R$: { sale_value }</p>
                <p className='text-muted truncate-line-2' style={{color: '#D0CBC9 !important', fontWeight: 600}}>Min: { estimated_time }</p>

                <div className='row g-2'>
                    {!!tags &&
                        tags.map((tag) => (
                            <div key={tag.text} className='col-auto'>
                                <Badge isLight color={tag.color} className='px-3 py-2'>
                                    {tag.text}
                                </Badge>
                            </div>
                        ))}
                </div>
            </CardBody>
        </Card>
    );
};

export default ItemExternalSchedule;