import React, { lazy } from 'react';
import { pages } from '../../menu';

export const PREFIX = 'admin/*';
export const PREFIX_MASTER = 'master/*';

const ADMIN = {

    USER: {
        EDIT: lazy(() => import('../../pages/Admin/User/Edit'))
    },

    FIRST_ACCESS: lazy(() => import('../../pages/Admin/FirstAccess')),

    CONFIGURATION: lazy(() => import('../../pages/Admin/Configuration')),

    REPORTS: lazy(() => import('../../pages/Admin/Reports/Index')),

    RAFFLE: lazy(() => import('../../pages/Admin/Raffle/Index')),

    PLAN: lazy(() => import('../../pages/Admin/Plan/Index')),

    SCHENDULE: {
        EDIT: lazy(() => import('../../pages/Admin/BlockSchendule/Edit')),
        LIST: lazy(() => import('../../pages/Admin/BlockSchendule/List'))
    },
};

const MASTER = {
    COMPANY: {
        EDIT: lazy(() => import('../../pages/Admin/Master/Company/Edit')),
        LIST: lazy(() => import('../../pages/Admin/Master/Company/List'))
    },

    USER_COMPANY: {
        LIST: lazy(() => import('../../pages/Admin/Master/UserCompany'))
    }
};

const presentation = [
    {
        path: pages.admin.configuration.path,
        element: <ADMIN.CONFIGURATION />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.admin.firstAccess.path,
        element: <ADMIN.FIRST_ACCESS />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.admin.reports.path,
        element: <ADMIN.REPORTS />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.admin.plan.path,
        element: <ADMIN.PLAN />,
        exact: true,
        // role: 'ROLE_MANAGER'
    },
    {
        path: pages.admin.raffle.path,
        element: <ADMIN.RAFFLE />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.admin.blockSchendule.list.path,
        element: <ADMIN.SCHENDULE.LIST />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.admin.blockSchendule.editID.path,
        element: <ADMIN.SCHENDULE.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: `${pages.admin.blockSchendule.editID.path}/:id`,
        element: <ADMIN.SCHENDULE.EDIT />,
        exact: true,
        role: 'ROLE_MANAGER'
    },
    {
        path: pages.master.company.editID.path,
        element: <MASTER.COMPANY.EDIT />,
        exact: true,
        role: 'ROLE_MASTER'
    },
    {
        path: `${pages.master.company.editID.path}/:id`,
        element: <MASTER.COMPANY.EDIT />,
        exact: true,
        role: 'ROLE_MASTER'
    },
    {
        path: pages.master.company.list.path,
        element: <MASTER.COMPANY.LIST />,
        exact: true,
        role: 'ROLE_MASTER'
    },
    {
        path: pages.master.userCompany.list.path,
        element: <MASTER.USER_COMPANY.LIST />,
        exact: true,
        role: 'ROLE_MASTER'
    },
    {
        path: pages.user.editID.path,
        element: <ADMIN.USER.EDIT />,
        exact: true,
        role: 'ROLE_EMPLOYEE'
    }
]

export default presentation;