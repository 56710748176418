import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import { WizardContextProvider } from './contexts/wizardContext';
import { Provider } from 'react-redux'
import store from './store/app';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<StrictMode>
			<ThemeContextProvider>
				<AuthContextProvider>
					<WizardContextProvider>
						<Provider store={store}>
							<App />
						</Provider>
					</WizardContextProvider>
				</AuthContextProvider>
			</ThemeContextProvider>
		</StrictMode>
	</BrowserRouter>
);

reportWebVitals();
