import React from "react";
import OffCanvas, {OffCanvasBody, OffCanvasHeader, OffCanvasTitle} from "../../components/bootstrap/OffCanvas";
import PropTypes from "prop-types";
import Card, {CardBody} from "../../components/bootstrap/Card";
import { STATUS, STATUS_DESC } from '../../helper/statusCompany';
import axios from "../../services/api";
import showNotification from "../../components/extras/showNotification";

const SelectCompany = ({ isOpen, setIsOpen, companys, loginSuccess }) => {
    const login = (companyId) => {
        axios.post('/user/setCompany', {
            'companyId': companyId
        }).then(res => {
			if (res.data.error) {
                showNotification('Atenção!', res.data.message, 'warning');
                setIsOpen(false);
			} else {
                loginSuccess(res)
			}
        })
    }

    return (
        <OffCanvas
            setOpen={setIsOpen}
            isOpen={isOpen}
            titleId='upcomingEdit'
            isBodyScroll
            placement='end'
        >
            <OffCanvasHeader setOpen={setIsOpen}>
                <OffCanvasTitle id='upcomingEdit'>Selecione uma empresa</OffCanvasTitle>
            </OffCanvasHeader>
            <OffCanvasBody>
                <Card stretch>
                    <CardBody>
                        <div className='row'>
                            <table className='table table-modern table-hover'>
                                <thead>
                                <tr>
                                    <th scope='col'>Imagem</th>
                                    <th scope='col'>CNPJ</th>
                                    <th scope='col'>Razão Social</th>
                                    <th scope='col'>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {companys.map((company, key) => (
                                    <tr key={key} onClick={() => login(company.id)}>
                                        <th>
                                            <img src={process.env.REACT_APP_HOST_API + (company.file_system !== null ? company.file_system.path : '/images/noImage.png')} alt='teste' width={54} height={54} />
                                        </th>
                                        <th>{ company.cnpj_cpf }</th>
                                        <th>{ company.social_reason }</th>
                                        <th className='h5 text-center'>
                                            <span className={STATUS[company.status]}>
                                                { STATUS_DESC[company.status] }
                                            </span>
                                        </th>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </OffCanvasBody>
        </OffCanvas>
    )
}

SelectCompany.propTypes = {
    companys: PropTypes.array,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    loginSuccess: PropTypes.func
};

export default SelectCompany;