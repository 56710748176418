import api from '../services/api';
import moment from 'moment';

export const getFirstLetter = (text, letterCount = 2) =>
    text
        .toUpperCase()
        .match(/\b(\w)/g)
        .join('')
        .substring(0, letterCount);


export const formatMoney = (valor, c = 2, d = ',', t = '.') => {
    c = isNaN(c = Math.abs(c)) ? 2 : c;
    d = d === undefined ? "." : d;
    t = t === undefined ? "," : t

    var n = valor,
        s = n < 0 ? "-" : "",
        i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
        j = (i.length) > 3 ? (i.length % 3) : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

export const formatDate = (date, time = true) => {
    return moment(date).format(`DD/MM/YYYY ${time ? 'HH:mm' : ''}`);
};

export const formatForCalc = valor => {
    if (valor === undefined || valor === null || valor === '' || valor === '0,00' || typeof valor !== 'string') {
        return 0
    }
    
    return parseFloat(valor.split('.').join("").replace(',', '.'))
}

export const searchCep = async (cep) => {
    let cepText = cep.replace(/\.|\-|\_/g, '');
    
    if (cepText.length === 8) {
        try {
            let response = await api.post(`/base/searchCep/${cepText}`);
            return response.data;
        } catch(exception) {
            return null;
        }
    } 
}

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getOS = () => {
    const { userAgent } = window.navigator;
    const { platform } = window.navigator;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'MacOS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    document.documentElement.setAttribute('os', os);
    return os;
}


export const isMobile = () => {
    const { userAgent } = window.navigator;
    const { platform } = window.navigator;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (iosPlatforms.indexOf(platform) !== -1 || /Android/.test(userAgent)) {
        return true
    }
    return false;
}
