import React, {useContext, useState} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import LogoSvg from '../../assets/img/LOGOEIBARBER.svg';
import {dashboardMenu, pages} from '../../menu';
import showNotification from "../../components/extras/showNotification";
import axios from "../../services/api";
import { setToken } from "../../services/auth";
import AuthContext from "../../contexts/authContext";
import Spinner from '../../components/bootstrap/Spinner';
import SelectCompany from "./SelectCompany";

const initialStateUser = {
	email: '',
	password: '',
};

const Login = () => {
	const [loading, setLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [companys, setCompanys] = useState([]);
	const [userLogin, setUserLogin] = useState(initialStateUser);
	const navigate = useNavigate();
	const location = useLocation();
	const { setAuth } = useContext(AuthContext);
	let from = location.state?.from?.pathname || dashboardMenu.schendule.path;

	const loginSuccess = (res) => {
		showNotification('Sucesso!', `Seja bem-vindo, ${res.data.user.full_name}`, 'success', true);
		setAuth(res.data);

		if (parseInt(res.data.user.current_company.first_access)) {
			showNotification('Atenção!', `Como este é seu primeiro acesso, ajude o EiBarber a controlar sua barbearia, configure o sistema de acordo com suas necessidades.`, 'warning', true);
			return navigate(pages.schendule.dashboardBooking.path, { replace: true });
		}

		if (from.includes('edit')) {
			return navigate('/');
		}
		return navigate(from, { replace: true });
	}

	const resetPassword = () => {
		setLoading(true);
		return axios.post('/auth/sendResetPassword', { ...userLogin }).then(res => {
			setLoading(false);

			if (res.data.error) {
				showNotification('Atenção!', res.data.message, 'warning', true);
			} else {
				showNotification('Sucesso!', res.data.message, 'success', true);
			}
		})
	}

	const login = () => {
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userLogin.email)) {
			return showNotification('Atenção!', 'Email inválido, corriga antes de continuar', 'info', true);
		}

		if (!userLogin.password) {
            return showNotification('Atenção!', 'Favor inserir sua senha.', 'warning', true);
		}

		setLoading(true);
		return axios.post('/auth/login', {
			...userLogin,
			'internal_description': {
				'userAgent': window.navigator.userAgent,
				'platform': window.navigator.platform
			}
		}).then(res => {
			setLoading(false);

			if (res.data.error) {
			    setUserLogin({ ...userLogin, password: '' });
			    showNotification('Atenção!', res.data.message, 'warning', true);
			} else {
				setUserLogin(initialStateUser);
				setToken(res.data.token);
				if (res.data.companys.length > 1) {
					setCompanys(res.data.companys);
					setIsOpen(true);
				} else {
					loginSuccess(res);
				}
			}
        }).catch(err => {
			console.warn(err);
			setLoading(false);
		})
	};

	return (
		<PageWrapper title='Login' className='bg-dark'>
			<Page className='p-0'>
				<SelectCompany
					companys={companys}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					loginSuccess={loginSuccess}
				/>

				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page' style={{marginTop: '1.5rem'}}>
							<CardBody>
								<div className='image-center my-5 text-center'>
									<img src={LogoSvg} alt='Logo' width={350} height={250} />
								</div>
								<div className='col-12' color='warning'>
									<div className='text-center h1 fw-bold mt-5'>Bem vindo!</div>
									<div className='text-center h4 text-muted mb-5'>
										Faça o login para continuar!
									</div>
								</div>

								<div className='row g-4'>
									<div className='col-12'>
										<FormGroup id='login-username' isFloating label='E-mail' style={{'marginBottom': '10px'}}>
											<Input
												type='email'
												autoComplete='username'
												name='email'
												onChange={(e) =>
													setUserLogin({ ...userLogin, email: e.target.value })
												}
												value={userLogin.email}
											/>
										</FormGroup>

										<FormGroup id='login-password' isFloating label='Senha'>
											<Input
												type='password'
												autoComplete='password'
												onChange={(e) =>
													setUserLogin({
														...userLogin,
														password: e.target.value,
													})
												}
												name='password'
												value={userLogin.password}
											/>
										</FormGroup>

										<div className="col-xs-6 text-rigth" style={{marginTop: 8, textAlign: 'end'}}>
											<a onClick={resetPassword}>Esqueceu sua senha?</a>
										</div>
									</div>
									<div className='col-12'>
										<Button
											color='green'
											className='w-100 py-3'
											onClick={login}
											disabled={loading}>
											{
												loading && (
													<Spinner isSmall inButton />
												)
											}
											{loading ? 'Aguarde...' : 'Entrar' }
										</Button>
									</div>
								</div>

								<div className='text-center' style={{marginTop: 16}}>
									<a href='/' className='link-light text-decoration-none me-3'>
										Aviso de Privacidade
									</a>
									<a href='/' className='link-light text-decoration-none'>
										Termos e Contrato de Uso
									</a>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
    );
};

export default Login;
