import React, {useContext, useEffect, useState} from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import {pages} from "../../menu";
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import Select from '../../components/bootstrap/forms/Select';
import ItemExternalSchedule from './Components/ItemExternalSchedule';
import {useParams} from "react-router-dom";
import api from "../../services/api";
import { formatForCalc, formatMoney } from "../../helper/functions";
import Wizard, {WizardItem} from "../../components/Wizard";
import wizardContext from "../../contexts/wizardContext";
import showNotification from "../../components/extras/showNotification";
import Card, { CardBody } from '../../components/bootstrap/Card';
import ModalCustomer from './Components/ModalCustomer';
import ModalSchedules from './Components/ModalSchedules';
import Icon from "../../components/icon/Icon";
import moment from 'moment';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Modal, {ModalBody, ModalHeader, ModalTitle} from "../../components/bootstrap/Modal";
import Spinner from "../../components/bootstrap/Spinner";
import { removeToken } from "../../services/auth";

const STEPS = { SCHEDULE: 'SCHEDULE', CONCLUDED: 'CONCLUDED' }

const ExternalSchedule = () => {
	const { setValidNext } = useContext(wizardContext);
	const [loading, setLoading] = useState(false);
	const { company } = useParams();
	const [ companySelected, setCompanySelected ] = useState(null);
	const [ nameCustomer, setNameCustomer ] = useState('');
	const [ isAndroid, setIsAndroid ] = useState(false);
	const [ currentStep, setCurrentStep ] = useState(STEPS.SCHEDULE);
	const [ postParam, setPostParam ] = useState(STEPS.SCHEDULE);
	const [ userAuth, setUserAuth ] = useState(null);
	const [ isOpenCustomer, setIsOpenCustomer ] = useState(false);
	const [ isOpenSchedules, setIsOpenSchedules ] = useState(false);
	const [ isOpenModalConfirm, setIsOpenModalConfirm ] = useState(false);
	const [ services, setServices ] = useState([]);
	const [ hours, setHours ] = useState([]);
	const [ barbers, setBarbers ] = useState([]);
	const [ formSchedule, setFormSchedule ] = useState({
		'barber_id': null,
		'date': null,
		'service_ids': [],
		'value': '0,00',
		'estimatedTime': 0
	});

	const handleService = (id) => {
		let servicesSelected = formSchedule.service_ids;

		if (formSchedule.service_ids.includes(id)) {
			setFormSchedule({
				...formSchedule,
				service_ids: servicesSelected.filter(item => item !== id)
			});
		} else {
			setFormSchedule({
				...formSchedule,
				service_ids: [ ...formSchedule.service_ids, id ]
			});
		}
	}

	const getCompany = () => {
		api.post(`externalSchedule/getCompany`, {'company': company}).then(resp => {
			if (resp.data.company === null) {
				showNotification('Atenção!', 'Nenhuma barbearia encontrada, entre em contato com seu barbeiro!', 'warning', true);
				setTimeout(function () {
					return window.location.href = 'https://eibarber.com.br/'
				}, 2000)
			}
			setCompanySelected(resp.data.company);
			setServices(resp.data.services);
		});
	}

	const getHours = () => {
		api.post(`externalSchedule/getSchendule`, {
			'services': formSchedule.service_ids,
			'timeEstimated': formSchedule.estimatedTime,
			'valueTotal': formSchedule.value,
			'barberId': formSchedule.barber_id !== null && formSchedule.barber_id !== 'ALL' ? formSchedule.barber_id : null,
			'date': formSchedule.date,
			'companyId': companySelected.id
		}).then(resp => {
			if (resp.data.error) {
				showNotification('Atenção!', resp.data.message, 'warning', true);
				setHours([]);
				return false;
			}

			if (resp.data.hours.length <= 0) {
				showNotification('Atenção!', 'Nenhum horário encontrado.', 'warning', true);
				setHours([]);
			} else {
				showNotification('Sucesso!', 'Encontramos alguns horário, escolha o melhor para você.', 'success', true);
				setHours(resp.data.hours);
			}
		});
	}

 	const toSchedule = (hour, barberId) => {
		let payload = {
			'recurrent': "0",
			'time_aggregate': 0,
			'time_estimated': formSchedule.estimatedTime,
			'value': formSchedule.value,
			'end': '',
			'start': hour,
			'date': formSchedule.date,
			'barber_id': barberId,
			'companyId': companySelected.id,
			'services': formSchedule.service_ids,
			'customer': null,
			'name_customer': nameCustomer
		};

		setLoading(true)
		api.post(`externalSchedule/toSchedule`, payload).then(resp => {
			setLoading(false)
			if (resp.data.error) {
				showNotification('Atenção!', resp.data.message, 'warning', true);
			} else {
				setCurrentStep(STEPS.CONCLUDED);
				removeToken();
				showNotification('Sucesso!', resp.data.message, 'success', true);
			}
		});
	}

	const getPlatform = () => {
		const { userAgent } = window.navigator;
		const { platform } = window.navigator;
		const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

		if (iosPlatforms.indexOf(platform) !== -1) {
			setIsAndroid(false)
		} else if (/Android/.test(userAgent)) {
			setIsAndroid(true)
		}
	}

	const getModal = () => (
		<Modal
			setIsOpen={setIsOpenModalConfirm}
			isOpen={isOpenModalConfirm}
			isStaticBackdrop
			isScrollable
			data-tour='search-modal'>
			<ModalHeader>
				<ModalTitle id='modalConfirm' className='text-success text-center'>
					<Icon icon='People' size='2x' color='success' />
					Agendar
				</ModalTitle>
			</ModalHeader>

			<ModalBody>
				<div className='row g-4'>
					<div className='col-12'>
						<FormGroup id='nameCustomer' isFloating label='Nome Completo'>
							<Input
								autoComplete='nameCustomer'
								placeholder='Nome Completo'
								name='nameCustomer'
								onChange={(e) => setNameCustomer(e.target.value)}
								value={nameCustomer}
							/>
						</FormGroup>
					</div>

					<div className='col-12'>
						<Button color='green' className='w-100 py-3' disabled={loading} onClick={ ()=> {
							if (!nameCustomer) {
								return showNotification('Atenção!', 'Digite o nome antes de agendar.', 'warning', true);
							}

							return toSchedule(postParam.hour, postParam.barberId);
						} }>
							{
								loading && (
									<Spinner isSmall inButton />
								)
							}
							{loading ? 'Aguarde...' : 'Agendar' }
						</Button>

						<Button
							color='primary'
							className='w-100 py-3'
							style={{ marginTop: 10 }}
							onClick={() => {
								setIsOpenModalConfirm(false);
								setIsOpenCustomer(true);
							}}
						>
							Já tenho conta
						</Button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	)

	useEffect(() => {
		removeToken();
		setIsOpenCustomer(true);
		getPlatform();
		getCompany();
		setValidNext(false);
		setFormSchedule({ ...formSchedule, date: moment().format('DD/MM/YYYY') });
	}, []);

	useEffect(() => {
		let value = 0, time = 0;

		services.forEach(item => {
			if (formSchedule.service_ids.includes(item.id)) {
				value += formatForCalc(item.sale_value);
				time += item.estimated_time;
			}
		});

		setFormSchedule({
			...formSchedule,
			value: formatMoney(value),
			estimatedTime: time
		})

		if (formSchedule.service_ids.length > 0) {
			api.post(`externalSchedule/getBarbersWithService`, {'services': formSchedule.service_ids, 'companyId': companySelected.id}).then(resp => {
				if (resp.data.length > 0) {
					let barbersList = [{ value: 'ALL', text: 'Todos' }];
					resp.data.forEach(bar => {
						barbersList.push({ value: bar.id, text: bar.full_name });
					})
					setBarbers(barbersList);
					setValidNext(true);
				} else {
					setBarbers(resp.data);
					setValidNext(false);
				}

			});
		} else {
			setValidNext(false);
		}

	}, [formSchedule.service_ids]);

	return (
		<PageWrapper  title={ pages.externalSchedule.text }>
			<Page>

				{
					currentStep === STEPS.SCHEDULE && (
						<>
							<ModalSchedules
								setIsOpen={setIsOpenSchedules}
								isOpen={isOpenSchedules}
								user={userAuth}
								company={companySelected}
							/>

							<ModalCustomer
								concluded={ (data) => {
									setIsOpenCustomer(false);
									setUserAuth(data);
								} }
								setIsOpen={setIsOpenCustomer}
								isOpen={isOpenCustomer}
								company={companySelected}
							/>

							{ getModal() }

							<Wizard
								notSave notNavigate isHeader='withButton' stretch color='primary'
								className='shadow-3d-primary'
								handleAuxFunc={() => {
									if (userAuth !== null) {
										setIsOpenSchedules(true);
									} else {
										setIsOpenCustomer(true);
									}
								}}
								textAux={userAuth !== null ? 'Cancelar Agendamento' : 'Entrar'}
								isAuxButton
							>
								<WizardItem id='step1' title='Serviço'>
									<div className='row'>
										<div className='col-12 text-center mb-2'>
											<span className='display-5 fw-bold'>EiBarber Agenda - { companySelected === null ? ' ' : companySelected.fantasy_name }</span>
										</div>
										<div className='col-12 text-center mb-2'>
											<span className='display-6 fw-bold text-primary'>Cliente - { userAuth === null ? 'Não Autentificado' : userAuth.user.full_name }</span>
										</div>
									</div>

									<div className='row mb-5'>
										{services.map((item) => (
											<div key={item.id} className='col-xl-2 col-lg-3 col-md-3'>
												<ItemExternalSchedule
													{...item}
													funcSelect={ handleService }
													isSelected={ formSchedule.service_ids.includes(item.id) }
												/>
											</div>
										))}
									</div>
								</WizardItem>

								<WizardItem id='step2' title='Barbeiro'>
									<div className='row'>
										<div className='col-12 text-center mb-2'>
											<span className='display-5 fw-bold'>EiBarber Agenda - { (companySelected === null || companySelected.fantasy_name === undefined) ? ' ' : companySelected.fantasy_name }</span>
											<h2 style={{marginTop: '30px', color: '#D0CBC9'}}>Resumo</h2>
											<p className='text-success truncate-line-1' style={{marginTop: '6px', fontSize: '24px', fontWeight: 'bold'}}>Valor Total: R$ { formSchedule.value }</p>
											<p className='text-success truncate-line-1' style={{fontSize: '18px', fontWeight: 'bold'}}>Tempo Total: Min { formSchedule.estimatedTime }</p>
										</div>

										<div className='col-12 text-center mb-2'>
											<span className='display-6 fw-bold text-primary'>Cliente - { userAuth === null ? 'Não Autentificado' : userAuth.user.full_name }</span>
										</div>

										<div className='col-xxl-6 mx-auto text-center mt-5'>
											<form className='row pb-4 px-3 mx-0 g-4 rounded-3 bg-lo25-primary'>
												<div className='col-md-5'>
													<FormGroup label='Data'>
														<Input
															size='lg'
															id='date'
															placeholder='Data'
															ariaLabel='Data'
															className='rounded-1'
															type='date'
															value={formSchedule.date}
															onChange={(e) => setFormSchedule({ ...formSchedule, date: e.target.value })}
														/>
													</FormGroup>
												</div>

												<div className='col-md-5'>
													<FormGroup label='Barbeiro'>
														<Select
															id='barber_id'
															size='lg'
															ariaLabel='Barbeiro'
															placeholder='Barbeiro'
															list={ barbers }
															className='rounded-1'
															onChange={(e) => setFormSchedule({ ...formSchedule, barber_id: e.target.value })}
															value={formSchedule.barber_id}
														/>
													</FormGroup>
												</div>

												<div className='col-md-2'>
													<Button
														size='lg'
														icon='Search'
														color='primary'
														className='w-100'
														rounded={1}
														onClick={ getHours }
														type='button'
													/>
												</div>
											</form>
										</div>
									</div>

									<div className='row'>
										{
											hours.length ?
												hours.map((item) => (
													<Card borderColor='secondary' stretch>
														<CardBody>
															<div className='row g-4'>
																<img src={process.env.REACT_APP_HOST_API + (item.barber.file_system !== null ? item.barber.file_system.path : '/images/noImage.png')}
																	 alt='' width='100%' height='100%' style={{maxWidth: '242px', borderRadius: '150', maxHeight: '300px'}} className='object-fit-contain p-3'
																/>
															</div>

															<div className='row g-4'>
																<div className='col-12'>
																	<div className='row g-3 align-items-center'>
																		{
																			item.hours.map(hour => (
																				<div className='col-auto'>
																					<Button
																						color='primary'
																						size='lg'
																						onClick={ () => {
																							if (userAuth !== null) {
																								toSchedule(hour, item.barber.id);
																							} else {
																								setPostParam({'hour': hour, 'barberId': item.barber.id});
																								setIsOpenModalConfirm(true);
																							}
																						} }
																						icon='MoreTime'
																						// shadow='none'
																						hoverShadow='lg'>
																						{ hour }
																					</Button>
																				</div>
																			))
																		}
																	</div>
																</div>
															</div>

															<div className='row'>
																{/*{*/}
																{/*}*/}
															</div>


															{/*<div className='row'>*/}
															{/*		<img src={process.env.REACT_APP_HOST_API + (item.barber.file_system !== null ? item.barber.file_system.path : '/images/noImage.png')}*/}
															{/*			 alt='' width='100%' height='100%' style={{marginRight: '20px', maxWidth: '250px', borderRadius: '50%', maxHeight: '300px'}} className='object-fit-contain p-3'*/}
															{/*		/>*/}
															{/*</div>*/}

															{/*<div className='row'>*/}
															{/*	{*/}
															{/*		item.hours.map(hour => (*/}
															{/*			<div className='col-xl-1 col-lg-1 col-md-1' style={{ padding: '5px' }}>*/}
															{/*				<Button*/}
															{/*					color='primary'*/}
															{/*					size='lg'*/}
															{/*					onClick={ () => toSchedule(hour, item.barber.id) }*/}
															{/*					shadow='none'*/}
															{/*					hoverShadow='lg'>*/}
															{/*					{ hour }*/}
															{/*				</Button>*/}
															{/*			</div>*/}
															{/*		))*/}
															{/*	}*/}
															{/*</div>*/}

														</CardBody>
													</Card>
												)) : (
													<span className='text-center'>Nenhum horário disponível na data informada.</span>
												)
										}
									</div>
								</WizardItem>

								<WizardItem id='step3' title='Agendamentos'>
									<div className='row'>
										<div className='col-12 text-center my-5'>
											<span className='display-5 fw-bold'>EiBarber Agenda - { companySelected === null ? ' ' : companySelected.fantasy_name }</span>
										</div>
									</div>

								</WizardItem>
							</Wizard>
						</>
					)
				}

				{
					currentStep === STEPS.CONCLUDED && (
						<>
							<div className='text-center text-success display-6 fw-bold pt-3 pb-5'>Que ótimo! Seu agendamento foi realizado com sucesso.</div>
							<div className='text-center display-12 fw-bold pt-3 pb-5'>
								<code style={{ color: '#72D8F8' }}>EiBarber</code>, seu próximo corte de cabelo está aqui!
							</div>

							<div className='text-center display-12 fw-bold pt-3 pb-5'>
								Conheça nosso aplicativo, realize o download e obtenha novos benefícios.
							</div>

							<div className='row'>
								<div className='text-center col-6'>
									<img src={process.env.REACT_APP_HOST_API + '/images/Tela1.jpeg'}
										 alt='' className='img-fluid object-fit-contain p-3'
									/>
								</div>

								<div className='text-center col-6'>
									<img src={process.env.REACT_APP_HOST_API + '/images/Tela2.jpeg'}
										 alt='' className='img-fluid object-fit-contain p-3'
									/>
								</div>
							</div>

							<div className='text-center display-12 fw-bold pt-3 pb-5'>
								Disponível para Android e IOS. Baixe agora!
							</div>

							<div className='col-12 text-center'>
								<Button
									icon='AppSettingsAlt'
									color='light'
									type='button'
									tag='a'
									href={
										isAndroid ?
											'https://play.google.com/store/apps/details?id=com.linksystem.appbarber' :
											'https://apps.apple.com/us/app/ei-barber/id1664266768'
									}
								>
									Quero Baixar
								</Button>
							</div>

							<div className='text-center display-12 fw-bold pt-3 pb-5'>
								Problemas com o botão? <a href='https://api.eibarber.com.br/downloadEiBarber'>Clique aqui</a>
							</div>
						</>
					)
				}

			</Page>
		</PageWrapper>
	);
};

export default ExternalSchedule;
