import React from 'react';
import { useMeasure } from 'react-use';
import moment from 'moment';

const Footer = () => {
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	return (
		<footer ref={ref} className='footer'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						<span className='fw-light'>© 2022-{ moment().format('YY') } - Version 1.0.12</span>
					</div>
					<div className='col-auto'>
						<a
							href='https://www.linksystem.com.br/'
							target='_blank'
							className='text-decoration-none link-dark'>
							<small className='fw-bold text-light'>Desenvolvido por Link System</small>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
