import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const WizardContext = createContext(null);

export const WizardContextProvider = ({ children }) => {
	const [validNext, setValidNext] = useState(true);
	const [onLoading, setOnLoading] = useState(false);
	const [onStep, setOnStep] = useState(0);

	const values = useMemo(
		() => ({
			onLoading,
			setOnLoading,
			validNext,
			setValidNext,
			onStep,
			setOnStep
		}),
		[validNext, setValidNext, onStep, setOnStep],
	);

	return <WizardContext.Provider value={values}>{children}</WizardContext.Provider>;
};
WizardContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default WizardContext;
